import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import TopDark from '../../Modules/Top/TopDarkRelative';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import Footer from '../../Modules/FooterPage';

// LOGO IMAGE 
import GrundfosSert from './Serts/grundfos_sert.jpg'

const GrundfosPageHtml = () => {
    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
            <div className="refeng-page-container" style={{display: 'grid', alignContent: 'center'}}>
                <div className="postcontent">
                    <Container fluid>
                        <Row>
                            <Col xl={8}>
                                <h4>Компания Grundfos</h4>
                                <br/>
                                <p>
                                    «Grundfos» работает в сфере водоснабжения с 1940-х годов, за это время компания внедрила
                                    довольно много инновационных решений в этой сфере.</p>
                                <p>
                                    «Grundfos» предлагает широкий ассортимент технологической продукции для различных сфер, таких как
                                    водоподготовка на предприятиях, регулирование температуры на предприятии, оборудование для 
                                    поддержания различных технологических процессов водоснабжения.
                                </p>
                                <p>
                                    <strong>Сайт компании:</strong>
                                    <a href="https://www.grundfos.com/" rel="noopener noreferrer nofollow" target = "_blank"  aria-label="Grundfos website">
                                    &nbsp;&nbsp;www.grundfos.com
                                    </a>
                                </p>
                            </Col>
                            <Col></Col>
                            <Col xl={2}>
                                <Zoom>
                                    <img
                                    alt="Сертификат о партнерстве компании Рефинжиниринг с Grundfos"
                                    src={GrundfosSert}
                                    width="100%"
                                    />
                                </Zoom>
                            </Col>
                            <Col></Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GrundfosPageHtml