import React from 'react';
import Seo from "../../components/seo"
import GrundfosPageHtml from '../../components/OtherPages/PartnersPages/grundfosspage';

function GrundfosPage() {
    return (
        <>
            <Seo 
                title="Партнер Рефинжиниринг Grundfos"
                description="Официальный партнер компании 'Рефинжиниринг'-Grundfos"
                keywords="Grundfos, электроника, контроллеры, холодильная, партнер"
            />
            <GrundfosPageHtml />
        </>
    );
}

export default GrundfosPage;